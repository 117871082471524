<template>
  <div>
    <div class="filter_wrap">
      <div class="container_wrap">
        <div class="intro_wrap">
          <div class="cover">
            <img :src="cur.cover_image" />
            <span class="hot" v-if="cur.cover_image_label && cur.cover_image_label != 'null'">{{ cur.cover_image_label }}</span>
            <span class="tag" v-if="cur.type">{{ cur.type }}</span>
          </div>
          <div class="intro">
            <h2>{{ cur.name }}</h2>
            <div class="intro_content">{{ cur.description }}</div>
            <div class="intro_action">
              <span><i class="iconfont icon-icon_huaban1"></i>{{view_num}}</span>
              <span :class="{ 'pointer': true, 'active': courseInfo.good.selected, 'disabled': is_like_disabled }"><i class="iconfont icon-icon-02" @click="actionHandler(0)"></i>{{ courseInfo.good.num }}</span>
              <span :class="{ 'pointer': true, 'active': courseInfo.collection.selected, 'disabled': is_like_disabled }"><i class="iconfont icon-icon-03" @click="actionHandler(1)"></i>{{ courseInfo.collection.num }}</span>
              <span class="pointer">
                <el-popover
                  placement="bottom"
                  trigger="click"
                  v-model:visible="sharePop.visible">
                  <template #reference>
                    <span class="el-dropdown-link">
                      <i class="iconfont icon-icon_huaban12" @click="getQRCode"></i>
                    </span>
                  </template>
                     <div style="text-align: center; padding-bottom: 10px">
                      <span>扫码分享</span>
                     </div>
                     <div class="ecodePop" style="text-align: center">
                        <img ref="myQRCode" :src="qrCodeUrl" style="width: 100px; height: 100px;"/>
                     </div>
                     <div class="share" >
                        <span></span>
                        <el-button class="copy" type="primary" size="mini" @click="copyPromoCode">复制网址</el-button>
                     </div>
                </el-popover>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container_wrap">
      <div class="suffix_wrap">
      <el-affix :offset="0">
        <div class="statistics_wrap">
          <div class="left">
            <div class="price"><span class="discount" v-if="cur.final_price != 0"><span>¥</span>{{ cur.final_price }}</span><span class="free" v-else>免费</span><span class="original" v-if="cur.mark_price">¥ {{ cur.mark_price }}</span></div>
            <div class="info">
              <span v-if="cur.start_at">开始时间：{{ cur.start_at }}</span>
              <span v-if="cur.end_at">结束时间：{{ cur.end_at }}</span>
              <span>难度：{{ cur.difficulty_alias }}</span>
              <span v-if="cur.city">地点：{{ cur.city }}</span>
            </div>
          </div>
          <div class="right" v-if="cur_order_loaded">
            <!-- 报名后 -->
            <div class="btns" v-if="canStudy">
              <el-button type="primary" v-if="cur.course_num > 0 && canExpt == true" @click="startStudy">进入学习</el-button>
              <el-button class="btn_info_common" v-if="cur.question_num > 0" @click="startExercise">进入做题</el-button>
              <!-- 报名额满 -->
              <span style="margin-left: 10px;" v-if="cur.auth_num > 0 && cur.people_num !=0 && order_stats.effective_count >= cur.people_num">
                <el-button type="btn_info_common" @click="" disabled>报名额满</el-button>
              </span>
              <span style="margin-left: 10px;" v-else-if="cur.state == 3 && cur.final_price != 0">

                <!-- 没有下单 -->
                <span v-if="order_stats.unpay.order_count == 0 && order_stats.uncashed.order_count == 0 && is_zone_user != true">
                  <el-button class="btn_info_common" @click="buyImmediately('curriculum', uuid)" v-if="cur.auth_num > 0">再次报名</el-button>
                  <el-button :class="{'btn_danger_common': !cur.cart_added, 'btn_info_common': cur.cart_added}"
                             :disabled="cur.cart_added"
                             @click="addCart" v-if="cur.auth_num > 0">
                    <span v-if="cur.cart_added">已加入购物车</span>
                    <span v-else>加入购物车 </span>
                  </el-button>
                </span>

                  <!-- 有下单未支付 -->
                  <span v-else style="margin-left: 10px;">

                    <el-button class="btn_danger_common" v-if="order_stats.uncashed.order_count > 0"
                       @click="goPath(`/center/order/`)">报名待收款</el-button>

                    <el-button type="danger" v-else-if="order_stats.unpay.order_count > 0"
                               @click="goPayUnPayOrder">报名待支付</el-button>
                  </span>


              </span>

            </div>
            <!-- 报名前 -->
            <div class="btns" v-else-if="cur.state == 3">
              <!-- 付费课程 -->
              <div v-if="cur.final_price != 0">

                  <!--用户逾期失效-->
                  <span v-if="order_stats.expired_count > 0">
                    <el-button type="btn_info_common" @click="" disabled>进入学习</el-button>
                  </span>

                  <!--############################ 购买课程相关按钮 ############################-->

                  <!-- 报名额满 -->
                  <span v-if="cur.people_num !=0 && order_stats.effective_count >= cur.people_num" style="margin-left: 10px;">
                      <el-button type="btn_info_common" @click="" disabled>报名额满</el-button>
                  </span>

                 <!--用户逾期失效-->
                 <span v-else-if="order_stats.expired_count > 0" style="margin-left: 10px;">

                    <el-button class="btn_danger_common" v-if="order_stats.uncashed.order_count > 0"
                               @click="goPath(`/center/order/`)">报名待收款</el-button>

                    <el-button type="danger" v-else-if="order_stats.unpay.order_count > 0"
                               @click="goPayUnPayOrder">报名待支付</el-button>

                   <el-button class="btn_info_common" v-else
                              @click="buyImmediately('curriculum', uuid)">重新报名</el-button>
                 </span>

                  <!-- 未报名 -->
                  <span v-else-if="order_stats.unpay.order_count == 0 && order_stats.uncashed.order_count == 0" style="margin-left: 10px;">
                    <el-button type="danger" @click="buyImmediately('curriculum', uuid)">立即报名</el-button>
                    <el-button v-if="cur.state == 3 && cur.final_price > 0 && cur.discount_count > 0" type="danger" @click="tabActiveName = 'discount'">领取优惠</el-button>
                    <el-button :class="{'btn_danger_common': !cur.cart_added, 'btn_info_common': cur.cart_added}"
                               :disabled="cur.cart_added" @click="addCart">
                      <span v-if="cur.cart_added">已加入购物车</span>
                      <span v-else>加入购物车</span>
                    </el-button>
                  </span>

                  <!-- 有下单未支付 -->
                  <span v-else style="margin-left: 10px;">

                    <el-button class="btn_danger_common" v-if="order_stats.uncashed.order_count > 0"
                       @click="goPath(`/center/order/`)">报名待收款</el-button>

                    <el-button type="danger" v-else-if="order_stats.unpay.order_count > 0"
                               @click="goPayUnPayOrder">报名待支付</el-button>
                  </span>
              </div>
              <!-- 免费课程 -->
            </div>
          </div>
        </div>
      </el-affix>
      </div>
      <div class="main_wrap">
        <div class="content">
          <div class="well_frame" style="min-height: 100%;">
            <el-tabs v-model="tabActiveName" class="tabs_demo">
              <el-tab-pane label="课程介绍" name="intro" v-if="cur.introduce">
                <div class="cur_intro ueditor_style">
                  <div v-html="cur.introduce" class="ueditor_content"></div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="教学大纲" name="syllabus" v-html="cur.outline" v-if="cur.outline" class="ueditor_style ueditor_content"></el-tab-pane>
              <el-tab-pane label="讲师简介" name="teachers" v-if="teacherList.length > 0">
                <ul class="teacher_list">
                  <li v-for="item in teacherList">
                    <div class="left"><img :src="item.picture"></div>
                    <div class="right">
                      <h3>{{item.name}}<span>{{item.title}}</span></h3>
                      <div class="content text_break">{{item.profile}}</div>
                    </div>
                  </li>
                </ul>
              </el-tab-pane>
              <el-tab-pane label="课程内容" name="chapter" v-if="chapter.length > 0">
                <DetailChapter v-bind="$attrs" :curUuid="uuid" :chapter="chapter" :video_trial_time="cur.video_trial_time" :video_trial_num="cur.video_trial_num"
                               :canStudy="canStudy" :curType="cur.type" @login="login" @messageShow="messageShow" @curTypeChange="changeCurType"></DetailChapter>
              </el-tab-pane>
              <el-tab-pane name="discount" v-if="cur.state == 3 && cur.final_price > 0 && cur.discount_count > 0">
                <template #label>
                  <span>领取优惠 <i class="iconfont icon-huo hot"></i></span>
                </template>
                <DetailDiscount :cur_uuid="uuid" v-bind="$attrs"></DetailDiscount>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <div class="side">
          <h4 class="modal_title">推荐课程</h4>
          <el-card :body-style="{ padding: '0px' }" shadow="hover" class="card_item_common" v-for="(item, index) in courseItem" :key="index">
            <div class="cover" @click="goCur('/course/detail/'  + item.uuid)">
              <img :src="item.cover_image" class="image">
              <span class="hot" v-if="item.cover_image_label && item.cover_image_label != 'null'">{{ item.cover_image_label }}</span>
              <span class="tag" v-if="item.type">{{ item.type }}</span>
            </div>
            <div class="content">
              <div class="title text_ellipsis" @click="goCur('/course/detail/'  + item.uuid)">{{ item.name }}</div>
              <div class="price_wrap">
                <el-tag size="small" type="danger" effect="dark" v-if="item.final_price === 0">免费</el-tag>
                <span class="discount_price" v-else><span>¥</span>{{ item.final_price }}</span>
                <span class="price" v-if="item.mark_price">¥ {{ item.mark_price }}</span>
                <span class="info">
                  <span v-if="item.position"> {{ item.position }} &nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span v-if="item.start_at"> {{ item.start_at }}</span>
                  <span v-else-if="item.duration && item.total_seconds > 0">{{ item.duration }}</span>
                  <span v-if="item.experiment_len">{{ item.experiment_len }}</span>
                </span>
              </div>
            </div>
            <div class="bottom">
              <span>
                <i class="iconfont icon-icon_huaban1"></i>{{ item.view }}
              </span>
              <span>
                <i class="iconfont icon-icon-02"></i>{{ item.like }}
              </span>
              <span>
                <i class="iconfont icon-icon-03"></i>{{ item.favor }}
              </span>
            </div>
          </el-card>
        </div>
      </div>
    </div>

    <!-- 用户信息 -->
    <el-dialog
        title="填写个人信息"
        v-model="userModal.user_message"
        width="500px"
        :before-close="cancel_message"
        >
      <el-alert title="请如实填写您的个人信息，以保证您的正常学习！" center type="warning" :closable="false" style="margin-bottom:20px;"></el-alert>
      <el-form :model="userModal.userList" :rules="rules" ref="infoForm" label-width="90px" >
        <el-form-item label="姓名" prop="realname">
            <el-input v-model.trim="userModal.userList.realname"></el-input>
        </el-form-item>
        <el-form-item label="职业" prop="job">
            <el-radio-group v-model="userModal.userList.job">
              <el-radio label="office">职员</el-radio>
              <el-radio label="student">学生</el-radio>
              <el-radio label="teacher">教师</el-radio>
            </el-radio-group>
          </el-form-item>
        <el-form-item label="单位/学校" prop="work_place">
            <el-input v-model.trim="userModal.userList.work_place"></el-input>
        </el-form-item>
        <el-form-item label="岗位/专业" prop="job_name">
            <el-input v-model.trim="userModal.userList.job_name"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="submitForm('infoForm')">确  定</el-button>
            <el-button @click="cancel_message">取  消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog
        title="提示"
        v-model="exptModal.visible"
        width="500px" @close="exptModal.visible = false">
      <p class="text-center">
        <i class="el-icon-warning" :style="exptModal.iconStyle"></i>
        <span :style="exptModal.textStyle">您存在课程实验正在运行，确定结束原实验吗？</span>
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="continue_old_expt()">取消，继续原实验</el-button>
          <el-button @click="create_new_expt()" type="primary">确定，开始新实验</el-button>
        </span>
      </template>

    </el-dialog>

  </div>
</template>
<script>
  import DetailChapter from "components/course/DetailChapter";
  import DetailDiscount from 'components/course/DetailDiscount'
  import { get, like, unlike, get_favor_like } from "api/course/course/create"
  import { GetUserState, LoginUser } from 'api/user/auth/login'
  import { addPath, checkPromoCode, checkMessage} from 'api/promoter/selectPromo'
  import { showMessage } from 'api/base/message'
  import { setCookie, getCookie } from 'api/base/axios'
  import { addCurToCart } from 'api/settlement/cart/user'
  import { getCurByCate, getCurOrderState,  getCurDetail } from "api/course/course/detail"
  import { prepareOrder } from 'api/settlement/order/user'
  import { fillOrderStatesDistributedInfo } from 'api/settlement/stats/admin'
  import { checkLogin } from "api/course/course/study"
  import { updateUserInfo } from 'api/user/user/user'
  import { checkExpt, deleteExpt,createUserRecord,continueExptRecord } from "api/experiment/list"
  import { startExpt } from "api/course/course/create"
  import { checkUserCanStudy } from 'api/course/course/list'
export default {
  name: 'CurDetail',
    components: {
      DetailChapter,
      DetailDiscount,
    },
    props: {
      cur_uuid: String
    },
    inject: ['reload'],
    data() {
      return {
        uuid: this.cur_uuid,
        qrCodeUrl: null,
        cur_order_loaded:false,
        show: false, //false的时候消失遮罩层
        imgUrl: ""  ,
        view_num: 0,
        canStudy: false,
        is_zone_user: false,
        orderCount: 0,
        like_check: false,
        is_like_disabled: true,
        canExpt: true,
        userModal:{
          user_message:false,
          userList:{
            realname:'',
            job:'office',
            work_place:'',
            job_name:''
          }
        },
        del_data:{
          uuid:'',
          name:'',
          expt_url:'',
          course_uuid:'',
          old_cur_uuid:''
        },
        new_data_uuid:'',
        exptModal: {
          visible: false,
          iconStyle: 'font-size:32px;color:#E6A23C;vertical-align: middle;margin-right:10px;',
          textStyle: 'vertical-align: middle;'
        },
        cur: {
          name: '',
          description: '',
          difficulty_alias: '',
          cover_image: '',
          cover_image_label: '',
          auth_num: 0,
          face_num: 0,
          video_num: 0,
          live_num: 0,
          experiment_num: 0,
          people_num: 0,
          teacher_num : 0,
          course_ware_num: 0,
          question_num: 0,
          total_question_num: 0,
          class: '',
          type: '',
          final_price: '',
          mark_price: '',
          start_at: '',
          end_at: '',
          city: '',
          introduce: '',
          outline: '',
          video_trial_time: 0,
          video_trial_num: 0,
          people_num: 0,
          discount_count:0,
          cart_added: false
        },
        order_stats: {
          unpay_order: null,
          expired_count:0,
          effective_count:0,
          unpay: {order_count: 0},
          canceled: {},
          uncashed: {order_count: 0},
          payed: {},
          closed: {},
        },
        chapter: [],
        sharePop: false,
        suffixIsScroll: false,
        courseInfo: {
          good: {
            selected: false,
            num: 0,
          },
          collection: {
            selected: false,
            num: 0,
          }
        },
        tabActiveName: 'intro',
        courseItem: [],
        user:{
            is_authenticated: false,
            user_id: null,
            user_name: null
        },
        options:{
          margin:0,
          width:100,
          height:100
        },
        message:window.location.href,
        teacherList: [],
        promo_code:'',

        rules: {
          job_name: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          work_place: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ],
          realname: [
            { required: true, message: '必填项', trigger: 'blur' },
            { min: 1, max: 50, message: '字符长度限制在1到50个字符以内', trigger: 'blur' }
          ]
        }
      }
    },
  mounted() {
    if(this.uuid){
      this.getCurData()
      this.getCurDetail()
    }
    this.savePromoCode()
    if(this._isMobile()) {
      if (window.location.href.indexOf('mobile') == -1){
          window.location.href = window.location.href.replace(/course/g,"mobile/course")
      }
    }else{
      if (window.location.href.indexOf('mobile') != -1){
          window.location.href = window.location.href.replace(/mobile\//g,"")
      }
    }
  },
  methods: {
    getCurData() {
      if(this.uuid === '' || this.uuid === undefined || this.uuid === 'undefined'){
        return false;
      }
      let data = {'need_info': false}
      get(this.uuid, data).then(res => {
        if(res.code == 0) {
          var data = res.data
          this.cur.name = data.name
          this.cur.description = data.description
          this.cur.difficulty_alias = data.difficulty_alias
          this.cur.cover_image = data.cover_image
          this.cur.cover_image_label = data.cover_image_label
          this.cur.people_num = data.people_num
          this.cur.final_price = data.final_price
          this.cur.mark_price = data.mark_price
          this.cur.introduce = data.introduce.replace(/(.+)/g, ' <p>$1</p>')
          this.cur.outline = data.outline
          this.cur.video_trial_time = data.video_trial_time
          this.cur.video_trial_num = data.video_trial_num
          this.cur.state = data.state
          this.getCurOrder()
          document.title = `${ this.cur.name }-未来网络学堂`

          //reload 7moor js
          const s = document.createElement('script');
          s.type = 'text/JavaScript';
          s.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=a4c3d330-5dcc-11ea-b7ff-07dd5ece4b95&autoShow=true&language=ZHCN';
          document.body.appendChild(s);

        }
      })
    },
    async getCurDetail() {
      const that = this
      await getCurDetail(this.cur_uuid).then(res => {
        if(res.code == 0){
          var data = res.data
          this.cur.auth_num = data.auth_num
          this.cur.face_num = data.face_num
          this.cur.video_num = data.video_num
          this.cur.live_num = data.live_num
          this.cur.experiment_num = data.experiment_num
          this.cur.course_num = data.auth_num + data.face_num + data.video_num + data.live_num + data.experiment_num
          this.cur.other_class_num = data.auth_num + data.face_num + data.video_num + data.live_num
          this.cur.course_ware_num = data.course_ware_num
          this.cur.question_num = data.question_num
          this.cur.total_question_num = data.total_question_num
          this.cur.teacher_num = data.teachers.length
          this.teacherList = data.teachers
          this.cur.start_at = data.start_at ? data.start_at.split(' ')[0] : ''
          this.cur.end_at = data.end_at ? data.end_at.split(' ')[0] : ''
          this.cur.city = data.city
          this.cur.discount_count = data.discount_count
          this.cur.cart_added = data.cart_added
          var course_num = 0
          for(var i=0; i<data.cur_chapter.length; i++){
            course_num += data.cur_chapter[i].chapter_content.length
          }
          this.cur.class = data.cur_chapter.length + '章' + course_num + '节'
          if(this.cur.other_class_num == 0 ){
            this.canExpt = true

            let all_expt_info = 0
            let chapter_data = res.data['cur_chapter']
            for(let m=0;m<chapter_data.length;m++){
              let canexpt_num = 0
              let chapter_content = chapter_data[m]['chapter_content']
              for(let n=0;n<chapter_content.length;n++){
                if (chapter_content[n]['experiment']['state'] != 4){
                  canexpt_num += 1
                }
              }
              if(canexpt_num == chapter_content.length){
                  all_expt_info += 1
              }
            }
            if(all_expt_info == chapter_data.length){
                this.canExpt = false
            }
          }

          var include_types = []
          if(data.auth_num > 0){
            include_types.push('认证码')
          }
          if(data.face_num > 0){
            include_types.push('面授课')
          }
          if(data.live_num > 0){
            include_types.push('直播课')
          }
          if(data.experiment_num > 0){
            include_types.push('实验课')
          }
          if(data.video_num > 0){
            include_types.push('视频课')
          }
          if (include_types.length == 1){
            this.cur.type = include_types[0]
          }
          else if(include_types.length > 1) {
            this.cur.type = '组合课'
          }
          else {
            this.cur.type = ''
          }
          if(data.introduce){
            this.tabActiveName = 'intro'
          }
          else if(data.outline) {
            this.tabActiveName = 'syllabus'
          }
          else if(data.teachers.length > 0){
            this.tabActiveName = 'teachers'
          }
          else if(data.cur_chapter.length > 0){
            this.tabActiveName = 'chapter'
          }
          else{
            this.tabActiveName = 'discount'
          }
          this.chapter = data.cur_chapter
          this.getRelatedCur(data.categories)
          this.is_favor_like()
          this.chapter = data.cur_chapter
        }
      })
    },
    getFavorLike(courseItem){
      courseItem.forEach(item => {
        get_favor_like(item.uuid).then(res => {
          item.like = res.like.num
          item.favor = res.favor.num
          item.view = res.view_num
        })
      })
    },
    getCurOrder(){
      const that = this
      return getCurOrderState(this.uuid).then(res => {
        if(res.code == 0) {
          that.canStudy = res.can_study
          this.is_zone_user = res.is_zone_user
          this.orderCount = res.order_count

          let stats_distribute = fillOrderStatesDistributedInfo(res.states_distribution);

          that.order_stats.effective_count = res.effective_count;
          that.order_stats.expired_count = res.expired_count;
          that.order_stats.unpay_order = res.unpay_order;

           stats_distribute.data.forEach(item=>{
//             console.log(item.state)
            switch (parseInt(item.state)) {
              case 1 : { //待支付
                that.order_stats.unpay = item;
              } break;
              case 2 : { //已取消
                that.order_stats.canceled = item;
              } break;
              case 3 : { //待收款
                that.order_stats.uncashed = item;
              } break;
              case 4 : { //已支付
                that.order_stats.payed = item;
              } break;
              case 5 : { //已关闭
                that.order_stats.closed = item;
              } break;
            }

//            console.log(that.order_stats);


          });

           that.cur_order_loaded = true;

        }
      })
    },
    goPayUnPayOrder(){
        this.goPath(`/order/pay/${this.order_stats.unpay_order.uuid}`);
    },
    getRelatedCur(categories){
      const that = this
      let category_lv1_ids = []
      let category_lv2_ids = []
      for(let i=0; i<categories.length; i++){
        category_lv2_ids.push(categories[i].id)
        category_lv1_ids.push(categories[i].category_lv1_id)
      }
      let filters = {
        category_lv1_ids: category_lv1_ids.join(','),
        category_lv2_ids: category_lv2_ids.join(','),
        exclude_uuid: that.uuid
      }
      getCurByCate(filters).then(res => {
        that.courseItem = res.data

        that.getFavorLike(that.courseItem)
      }).catch(err => {
        that.$alert(`获取推荐课程失败`, '提示', {
          confirmButtonText: '确定',
        });
      })
    },
    is_favor_like(){
      get_favor_like(this.uuid).then(res => {
        if(res.like.is_true){
          this.courseInfo.good.selected = true
        }else{
          this.courseInfo.good.selected = false
        }
        if(res.favor.is_true){
          this.courseInfo.collection.selected = true
        }else{
          this.courseInfo.collection.selected = false
        }
        this.courseInfo.good.num = res.like.num
        this.courseInfo.collection.num = res.favor.num
        this.view_num = res.view_num
        this.is_like_disabled = false
      })
    },
    actionHandler(type) {
      let that = this
      if(this.like_check){
        return
      }else{
        this.like_check = true
      }
      if(type === 0) {
        if(this.courseInfo.good.selected) {
          unlike({'target_uuid': this.uuid, 'target_type': 'like'}).then(res => {
            if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                that.$emit('update:loginVisible', true)
            }else{
              that.courseInfo.good.num--
              that.courseInfo.good.selected = !that.courseInfo.good.selected;
              that.like_check = false
            }
          })
        } else {

          like({'target_uuid': this.uuid, 'target_type': 'like'}).then(res => {
            if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                that.$emit('update:loginVisible', true)
            }else{
              that.courseInfo.good.num++
              that.courseInfo.good.selected = !that.courseInfo.good.selected;
              that.like_check = false
            }
          })
        }
      }
      if(type === 1) {
        if(this.courseInfo.collection.selected) {
          unlike({'target_uuid': this.uuid, 'target_type': 'favor'}).then(res => {
            if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                that.$emit('update:loginVisible', true)
            }else{
              that.courseInfo.collection.num--;
              that.courseInfo.collection.selected = !that.courseInfo.collection.selected;
              that.like_check = false
            }
          })
        } else {
          like({'target_uuid': this.uuid, 'target_type': 'favor'}).then(res => {
            if (res.hasOwnProperty("error") && res.error === 'Not Login') {
                that.$emit('update:loginVisible', true)
            }else{
              that.courseInfo.collection.num++
              that.courseInfo.collection.selected = !that.courseInfo.collection.selected;
              that.like_check = false
            }
          })
        }
      }
    },

    async startStudy(){

      await checkMessage().then((res) => {
          if (res.data == 0) {

            let is_logined = false;

            GetUserState().then(result => {
              if (result.id == undefined) {
                this.$emit('update:loginVisible', true);
              } else {
                is_logined = true;
              }
              if (is_logined) {
                for (var j=0;j<=this.chapter.length-1;j++){
                  let data_course = this.chapter[j].chapter_content
                  for(var i=0;i<=data_course.length-1;i++) {
                    var state = data_course[i]
                    if (state['experiment'] && state['experiment']['state'] == 4){
                      var course_experiment = data_course[i]['experiment_uuid']
                      this.study_experiment(course_experiment)
                      return
                    }
                    else if (data_course[i].video_uuid) {
                      this.create_user_record('video',data_course[i].video_uuid)
                      return
                    }
                    else if (data_course[i].live_uuid) {
                      this.create_user_record('live',data_course[i].live_uuid)
                      return
                    }
                    else if (data_course[i].authentication_uuid) {
                      this.create_user_record('authentication',data_course[i].authentication_uuid)
                      return
                    }
                    else if (data_course[i].face_uuid) {
                      this.create_user_record('face_teaching',data_course[i].face_uuid)
                      return
                    }
                  }
                }
              }
            }).catch(err => {
              console.log(err);
            });
          }
          else {
            this.userModal.userList.realname = res.user.realname
            this.userModal.userList.job_name = res.user.job_name
            if (res.user.job != null){
              this.userModal.userList.job = res.user.job
            }else{
              this.userModal.userList.job = 'office'
            }
            this.userModal.userList.work_place = res.user.work_place
            this.userModal.user_message = true
          }
      })

    },
    create_user_record(type,uuid){
        var record_data = {'curr_uuid':this.uuid,'type':type,'uuid':uuid}
        createUserRecord(record_data).then(() => {
            this.goPath(`/course/detail/${type}/${this.uuid}/${uuid}`)
        })
    },
    study_experiment(experiment_uuid){
        var check_data = {'course_uuid':experiment_uuid,'cur_uuid':this.uuid}
        checkExpt(check_data).then((result) => {
          if (result.data.code == 0 && result.data.old_expt == false) {
            this.del_data.uuid = result.data.data['uuid']
            this.del_data.name = result.data.data['name']
            this.del_data.expt_url = result.data.data['expt_url']
            this.del_data.course_uuid = result.data.data['course_uuid']
            this.del_data.old_cur_uuid = result.data.old_cur_uuid
            this.new_data_uuid = experiment_uuid

            this.exptModal.visible = true
          } else if(result.data.code == 0 && result.data.old_expt == true){
            var record_data = {'curr_uuid':this.uuid,'expt_uuid':experiment_uuid,'instance_uuid':result.data.data.uuid}
            continueExptRecord(record_data).then(() => {
              this.$router.push(`/expt/detail/${result.data.data.uuid}/?cur_uuid=${this.uuid}&from=${window.location.pathname}`);
            })
          }else{
            this.create_expt(experiment_uuid)
          }
        }).catch(err => {
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });
    },
    continue_old_expt(){
      var record_data = {'curr_uuid':this.del_data.old_cur_uuid,'expt_uuid':this.del_data.course_uuid,'instance_uuid':this.del_data.uuid}
      continueExptRecord(record_data).then(() => {
        this.$router.push(`/expt/detail/${this.del_data.uuid}/?cur_uuid=${this.del_data.old_cur_uuid}&from=${window.location.pathname}`);
      })
    },
    create_new_expt(){
        //  关闭旧实验开启新实验
        var del_data = {'uuid': this.del_data.uuid,'name':this.del_data.name}
        deleteExpt(del_data).then((result_data) => {
          if (result_data.data.code == 0) {
            this.create_expt(this.new_data_uuid)
          }
        })
    },
    create_expt(uuid){
        //  开始新实验
      var data = {'course_uuid':uuid,'curr_uuid':this.uuid}
      startExpt(data).then((res) => {
        if (res.data.code == 0){
          this.$router.push(`/expt/detail/${res.data.expt_uuid}/?cur_uuid=${this.uuid}&from=${window.location.pathname}`);
        }else{
          this.$alert(`${res.data.err_message}`, '提示', {
            confirmButtonText: '确定'
          });
        }
      }).catch(err=>{
      this.$alert(`${err}`, '提示', {
        confirmButtonText: '确定'
      });
      });
    },
    async startExercise() {
      const { code: canStudy } = await checkUserCanStudy({'cur_uuid': this.uuid})
      if(!canStudy) {
        this.reload()
        return
      }

      checkMessage().then((res) => {
        if (res.data == 0) {
          checkLogin().then(res => {
            if (res.code == 1) {
              this.login()
            }
            else {
              this.goPath('/course/exercise/' + this.uuid)
            }
          })
        }else{
            this.userModal.userList.realname = res.user.realname
            this.userModal.userList.job_name = res.user.job_name
            if (res.user.job != null){
              this.userModal.userList.job = res.user.job
            }else{
              this.userModal.userList.job = 'office'
            }
            this.userModal.userList.work_place = res.user.work_place
            this.userModal.user_message = true
        }
      })
    },
    goCur(path) {
      let domain = document.location.origin
      let url = domain + path
      window.open(url, "_blank");
    },
    goPath(path) {
      this.$router.push(path)
    },
    selectPromo(){
      GetUserState().then((res) => {
        if (res.id) {
          this.user.user_id = res.id
          var select_id = {
              'user_id': this.user.user_id,
              'status': 0
          }
        }
        addPath(select_id).then((res) =>  {
          if (res.data != ''){
//            this.$router.replace( "?" + res.data)
            this.promo_code = res.data
//            var promo_code = res.data
//            setCookie('promo_code', promo_code, )
          }
        })
      })
    },
    savePromoCode(){
      let s = window.location.href
      var a = s.indexOf("?");
      var b = s.indexOf("=", a);
      if(b === -1){
        var code = s.substr(a+1,16);
      }else{
        var code = s.slice(a+1,b);
      }

      if (a != -1 && code != undefined){
        let filters = {'promo_codes': code,
                        'status': '0'}
        checkPromoCode(filters).then((res) => {
            if (res.data == 0){
              setCookie('promo_code', code, 3600)
              this.selectPromo()
            }else {
              setCookie('promo_code', '', 3600)
              this.selectPromo()
            }
        })
      }else{
        var old_code = getCookie('promo_code')
        let filters = {'promo_codes': old_code,
                        'status': '0'}
        checkPromoCode(filters).then((res) => {
            if (res.data == 0){
              setCookie('promo_code', old_code, 3600)
              this.selectPromo()
            }else {
              setCookie('promo_code', '', 3600)
              this.selectPromo()
            }
        })
      }
    },
    addCart(){
      addCurToCart(this.uuid).then(result=>{
        if (result.succeed) {
          this.$notify({
            title: '提示',
            message: '加入购物车成功',
            type: 'success',
            offset: 80
          });
          this.$emit('update:cartItemCount', result.item_count);
          this.cur.cart_added = true;
        } else {

          switch (result.error_code) {
            case -9999: {
              this.$emit('update:loginVisible', true);
            }
              break;
            default: {
              this.$notify({
                title: '提示',
                message: result.error_msg,
                type: 'warning',
                offset: 80
              });
            }
              break;
          }

        }
      }).catch(err => {
        this.$notify({
          title: '提示',
          message: err,
          type: 'warning',
          offset: 80
        });
      });
    },
    async buyImmediately(type, res_uuid){

      const that = this;
      checkMessage().then((res) => {
        if(res.data == 0){
          prepareOrder([{
            type: type,
            res_uuid: res_uuid
          }]).then(result => {

            if (result.succeed) {
              that.goPath(`/order/confirm/${result.confirm_key}`);
            } else {

              switch (result.error_code) {
                case -9999: {
                  this.$emit('update:loginVisible', true);
                }
                  break;
                default: {
                  this.$notify({
                    title: '提示',
                    message: result.error_msg,
                    type: 'warning',
                    offset: 80
                  });
                }
                  break;
              }

            }

          }).catch(err => {
            that.$alert(`${err}`, '提示', {
              confirmButtonText: '确定'
            });
          });
        }
        else{
            this.userModal.userList.realname = res.user.realname
            this.userModal.userList.job_name = res.user.job_name
            if (res.user.job != null){
              this.userModal.userList.job = res.user.job
            }else{
              this.userModal.userList.job = 'office'
            }
            this.userModal.userList.work_place = res.user.work_place
            this.userModal.user_message = true
        }
      })


    },
    copyPromoCode() {
      var oInput = document.createElement("input") //创建隐形input
      oInput.value = this.promo_code ? window.location.href + '?' + this.promo_code : window.location.href // window.location.href 浏览器URL
      document.body.appendChild(oInput)
      oInput.select()
      document.execCommand("Copy")
      oInput.remove()
      showMessage('课程链接已经复制到粘贴板','success');
    },
    getQRCode(){
      this.showShare = true;
      this.message = window.location.href + '?' + this.promo_code
      this.qrCodeUrl = `/api/promoter/share/qrcode/?data=${encodeURIComponent(this.message)}`;
    },
    login() {
      this.$emit('update:loginVisible', true)
    },
    changeCurType(curType){
      this.cur.type = curType
    },
    updateUserInfoMethod(data) {
      updateUserInfo(this.user.user_id, data)
        .then((res) => {
          this.loading = false;
          if (res.result === 0) {
            this.$router.go(0);
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            });
          }
        }).catch((err) => {
        this.loading = false;
        this.$alert('服务异常，请稍后再试', '提示', {
              confirmButtonText: '确定',
        });
      })
    },
    cancel_message() {
        this.$refs['infoForm'].resetFields()
        this.userModal.user_message = false
    },
    submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.updateUserInfoMethod({"job": this.userModal.userList.job, "job_name": this.userModal.userList.job_name, "work_place": this.userModal.userList.work_place, "realname": this.userModal.userList.realname});
          } else {
            return false;
          }
        });
    },
    messageShow() {
        this.userModal.user_message = true
    },
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }


  },
  watch: {
    'cur_uuid' (val) {
      this.uuid = val
      this.getCurData()
      this.getCurDetail()
    },
    '$route' (to, from) {
      if(to.name === 'CourseDetail'){
        this.uuid = to.params.uuid
        this.getCurData()
        this.getCurDetail()
      }
    }
  }
}
</script>

<style lang="scss">
  @import "~@/assets/css/ueditor.scss";
</style>

<style lang="scss" scoped>
  .filter_wrap {
    padding:20px 20px 80px 20px;
    .container_wrap {
      padding-bottom:0;
    }
  }
  .intro_wrap {
    display:flex;
    > .cover {
      width:280px;
      height:180px;
      position:relative;
      margin-right:20px;
      > img {
        width:100%;
        height:100%;
      }
      .hot {
        display:block;
        padding: 5px 20px 5px 10px;
        background: linear-gradient(to right, #f51e22 , #ff7350);
        border-right:1px solid #fff;
        border-bottom:1px solid #fff;
        border-bottom-right-radius: 30px;
        color:#fff;
        position:absolute;
        top:0;
        left:0;
        font-size:12px;
      }
      .tag {
        display:block;
        padding:5px 15px;
        background:rgba(0,0,0,0.5);
        color:#fff;
        position:absolute;
        bottom:10px;
        right:10px;
        font-size:12px;
      }
    }
    > .intro {
      flex:1;
      h2 {
        color:#333;
      }
      .intro_num {
        color: #666;
        margin-bottom: 20px;
        .spot {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #b4b4b4;
          vertical-align: middle;
          margin-left: 15px;
          margin-right: 15px;
        }
      }
      .intro_content {
        line-height: 1.5;
        margin-bottom:20px;
        word-wrap: break-word;
        word-break: break-word;
      }
      .intro_action {
         > span {
           padding-left:18px;
           border-left:2px solid #e2e2e2;
           margin-left:18px;
           color:#aaa;
           &:first-child {
              padding-left:0;
              border-left:none;
              margin-left:0;
           }
           i {
              margin-right:7px;
              color:#aaa;
           }
           &.pointer {
             i {
               cursor: pointer;
               &:hover {
                 color: #777777;
               }
             }
             &.active {
               i {
                 color: #09b0ef;
               }
             }
           }
         }
      }
    }
  }
  .suffix_wrap {
    margin-top:-75px;
    margin-bottom: 30px;
  }
  .statistics_wrap {
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 30px 50px;
    display: flex;
    .left {
      flex:1;
      .price {
        margin-bottom:20px;
        > span {
          vertical-align: middle;
          &.discount {
            font-family: 'font-540';
            font-size:44px;
            color:#f51c21;
            margin-right:25px;
            > span {
              margin-right:10px;
            }
          }
          &.original {
            color:#999;
            font-size:22px;
            text-decoration: line-through;
          }
          &.free {
            font-family: 'font-pingfang-bold';
            font-size:27px;
            font-weight:bold;
            color:#f51c21;
            margin-right:25px;
          }
        }
      }
      .info {
        > span {
          margin-right:40px;
        }
      }
    }
    .right {
      width:450px;
      text-align:right;
      position:relative;
      .btns {
        position: absolute;
        top:50%;
        right:0;
        transform: translateY(-50%);
      }
    }
  }
  .main_wrap {
    display:flex;
    .content {
      flex:1;
      .iconfont.hot {
        font-size: 13px;
        color: #F51C21;
        position: relative;
        top: -6px;
        left: -2px;
      }
    }
    .side {
      width:280px;
      margin-left:20px;
    }
  }
  :deep(.el-affix) {
    .el-affix--fixed {
      width:100% !important;
      left:0 !important;
      box-shadow: 0 0 10px rgb(0 0 0 / 10%);
      background:#fff;
      .statistics_wrap {
        width:1200px;
        margin: 0 auto;
        box-shadow: none;
      }
    }
  }
  .ecodePop {
    canvas {
      width:100% !important;
      height:auto !important;
    }
  }
  .share {
      padding:0 0 15px 0;
      text-align: center;
      img, span {
          display:block;
      }
      img {
          margin:0 auto 10px;
      }
      span {
          padding-bottom:10px;
          margin-bottom:10px;
          border-bottom:2px solid #f1f1f1;
          font-size:12px;
      }
  }
  .cur_intro {
    > h3 {
      font-size:16px;
      color:#333;
      margin-top:0;
      position:relative;
      padding-bottom:10px;
      padding-left:2px;
      &:after {
        content: '';
        position:absolute;
        left:0;
        bottom:0;
        width:100px;
        height:2px;
        background:url('~@/assets/img/title_bg.png') no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  .teacher_list {
    li {
      display:flex;
      padding:20px;
      background:#f9fafc;
      margin-bottom:20px;
      .left {
        width:100px;
        margin-right:30px;
        > img {
          width:100px;
          height:100px;
          border-radius:50%;
        }
      }
      .right {
        flex: 1;
        h3 {
          font-size:18px;
          color:#333;
          margin-top:0;
          margin-bottom:10px;
          > span {
            color:#666;
            margin-left:20px;
            font-weight: normal;
            vertical-align: middle;
            position:relative;
            font-size:14px;
            top:-2px;
          }
        }
        .content {
          color:#999;
          line-height:1.5;
        }
      }
    }
  }
</style>
