import { fetch, post, del } from 'api/base/axios'

const URL = {
  listUrl: '/nav/list/',
  saveUrl: '/nav/save/',
  delUrl: '/nav/delete/',
  getUrl: '/nav/get/',
  checkNameUrl: '/nav/check_name_exist/',
  checkUrlUrl: '/nav/check_url_exist/',
  selectPageNavUrl: '/nav/select_page_nav/',
  clickUrl: '/nav/click/',
}

export function listNav(data) {
  return fetch(URL.listUrl, data)
}

export function saveNav(data) {
  return post(URL.saveUrl, data)
}

export function delNav(id) {
  return del(URL.delUrl + id + '/')
}

export function getNav(id) {
  return fetch(URL.getUrl + id + '/')
}

export function checkNameExist(data) {
  return fetch(URL.checkNameUrl, data)
}

export function checkUrlExist(data) {
  return fetch(URL.checkUrlUrl, data)
}

export function selectNav(data) {
  return fetch(URL.selectPageNavUrl, data)
}

export function clickNav(id) {
  return fetch(URL.clickUrl + id + '/')
}

const courseType = {
  'video': '视频',
  'live': '直播',
  'course': '实验',
  'face_teaching': '面授',
  'authentication': '认证',
}
const innerpageType = {
  '/home': '首页',
  '/training': '企业内训',
  '/certificatequery': '证书查询',
  '/aboutus': '关于我们',
}


export function formatTableData(data) {
  data.forEach(item => {
    if(item.type == 1){
      item.link_to = '分类：'
      if(item.category_lv1){
        item.link_to += item.category_lv1.name + '-全部'
      }
      else if(item.category_lv2_list && item.category_lv2_list.length > 0){
        let cates = []
        item.category_lv2_list.forEach(cate => {
          cates.push(cate.category_lv1.name + '-' + cate.name)
        })
        item.link_to += cates.join('，')
      }
      else {
        item.link_to += '全部分类'
      }
    }
    else if(item.type == 2){
      item.link_to = '课程集合：课程章节组成：'
      let cur_filter = JSON.parse(item.cur_filter)
      if (cur_filter.chapter_include == ''){
        item.link_to += '不指定'
      }
      else if(cur_filter.chapter_include == 1){
        item.link_to += '全为' + courseType[cur_filter.chapter_type]
      }
      else {
        item.link_to += '含有'
        let types = []
        cur_filter.chapter_type.forEach(type => {
          types.push(courseType[type[0]])
        })
        item.link_to += types.join('、')
      }
      if(cur_filter.people_num == 1){
        item.link_to += '，人数：不限'
      }
      else if(cur_filter.people_num == 2){
        item.link_to += '，人数：有限'
      }
      if(cur_filter.price == 1){
        item.link_to += '，成交价：免费'
      }
      else if(cur_filter.price == 2){
        item.link_to += '，成交价：收费'
      }
    }
    else if(item.type == 3) {
      item.link_to = '课程：' + item.curriculum.name
    }
    else if(item.type == 4) {
      item.link_to = '页面：' + item.page.name
    }
    else if(item.type == 5){
      item.link_to = '链接：' + item.link_url
    }
    else if(item.type == 6){
      item.link_to = '内置页面：' + innerpageType[item.link_url]
    }
  })
  return data
}
