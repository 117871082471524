
import { fetch, post, del, postForm } from '../base/axios'

const URL = {
  listUrl: '/page/list/',
  createUrl: '/page/create/',
  delUrl: '/page/delete/',
  disableUrl: '/page/disable/',
  enableUrl: '/page/enable/',
  pageStateChange: '/page/page_state_change/',
  getFiles: '/page/get_files/'
}

export function pageList (data) {
  return fetch(URL.listUrl, data)
}

export function pageCreate(form) {
    return postForm(URL.createUrl, form)
}

export function pageDelete(page_id) {
    return del(URL.delUrl + page_id + '/')
}

export function pageDisable(data) {
    return fetch(URL.disableUrl, data)
}

export function pageEnable(data) {
  return fetch(URL.enableUrl, data)
}

export function pageStateChange(data) {
  return fetch(URL.pageStateChange, data)
}

export function pageGetFiles(page_id) {
    return fetch(URL.getFiles + page_id + '/')
}



