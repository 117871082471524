<template>
  <div id="detail_chapter">
    <!-- 课程未购买只能试看时，is_hover为false -->
    <dl :class="{ 'chapter_list': true, 'is_hover': canStudy }" v-for="(ch, index) in chapter_data">
      <dt v-if="chapter_data.length > 1 || ch.introduction" @click="ch.showIntro = !ch.showIntro" :style="{'cursor': ch.introduction === '' ? 'default' : 'pointer'}">
        <span>{{ ch.title }}</span>
        <i class="el-icon-arrow-down" v-if="ch.introduction && !ch.showIntro"></i>
        <i class="el-icon-arrow-up" v-if="ch.introduction && ch.showIntro"></i>
      </dt>
      <transition name="el-fade-in-linear">
        <div v-if="ch.introduction" v-show="ch.showIntro" class="intro ueditor_content ueditor_style" v-html="ch.introduction"></div>
      </transition>
      <dd v-for="(item, i) in ch.chapter_content" @click="goStudyPage(item)">
        <div class="left" v-if="item.face_uuid">
          <i class="iconfont icon-qqq_huaban1"></i>
          <span class="title">{{ index+1 }}. {{ i+1 }} {{ item.face_teaching.name }}</span>
          <span class="info">（{{ item.city }} · {{ item.start_at }}&nbsp;-&nbsp;{{ item.end_at }}）</span>
        </div>
        <div class="left" v-else-if="item.live_uuid">
          <i class="iconfont icon-qqq-02"></i>
          <span class="title">{{ index+1 }}. {{ i+1 }} {{ item.live.name }}</span>
          <span class="info">（{{ item.start_at }}&nbsp;-&nbsp;{{ item.end_at }}）</span>
        </div>
        <div class="left" v-else-if="item.video_uuid">
          <i class="iconfont icon-qqq-03"></i>
          <span class="title">{{ index+1 }}. {{ i+1 }} {{ item.video.name }}</span>
          <span class="info" v-if="item.video.cdn_resource.play_type == 'h5_hls'">（{{ item.video.duration }}）</span>
          <span class="info" v-else-if="item.video.cdn_resource.play_type == 'h5_hls_std'">（{{ item.video.duration }}）</span>
        </div>
        <div class="left" v-else-if="item.authentication_uuid">
          <i class="iconfont icon-tubiaocankaoxian1024-05"></i>
          <span class="title">{{ index+1 }}. {{ i+1 }} {{ item.authentication.name }}</span>
        </div>
        <div class="left" v-else-if="item.experiment_uuid" @mouseenter="enter" @mouseleave="leave">
          <i class="iconfont icon-tubiaocankaoxian1024-04-copy-copy"></i>
          <span class="title">{{ index+1 }}. {{ i+1 }} {{ item.experiment.course_name }}</span>
          <span class="info">（{{ item.experiment.duration }}课时）</span>
        </div>
        <div :class="{'right': true, 'show_btns': item.experiment_uuid && item.experiment.progress.state == 'running' && item.experiment.state == '4'}">
          <span v-if="item.live_uuid || item.face_uuid">{{ item.state }}</span>
          <span v-else-if="(item.video_uuid || item.authentication_uuid) && canStudy">{{ item.state }}</span>
          <el-button v-if="item.video_uuid && item.video_index < video_trial_num && video_trial_time && video_trial_time > 0 && !canStudy" type="danger" size="small" round @click="playVideo(item.video)">试 看</el-button>
          <div v-if="item.experiment_uuid && canStudy">
            <span v-if="item.experiment.progress.state == 'over' && item.experiment.state == '4' ">已学习</span>
<!--            <span v-if="item.experiment.progress.state == 'running' && item.experiment.state == '4' ">学习中</span>-->
            <span v-if="item.experiment.progress == '' && item.experiment.state == '4' ">未学习</span>
            <template v-if="canStudy">
              <el-button type="primary" size="small"
                         v-if="(item.experiment.progress.state == 'over' || item.experiment.progress == '' ) && item.experiment.state == '4' " @click="start_expt(item.experiment_uuid)">开始实验</el-button>
              <el-button type="primary" size="small" v-else-if="item.experiment.progress.state == 'running' && item.experiment.state == '4' "
                       @click="continue_expt(item.experiment_uuid)">继续实验</el-button>
              <el-button v-if="item.experiment.state == '4' " @click="preview_expt(item)" size="small">实验预览</el-button>
            </template>
            <span class="no_publish" v-if="item.experiment.state != '4' ">暂未发布</span>
          </div>
        </div>
      </dd>
    </dl>

    <!-- 试看 -->
    <el-dialog custom-class="try_watch_modal"
        title="试看"
        v-model="watchModal.visible"
        width="1000px" @close="handleClose">
       <!-- 视频 -->
      <div class="content" v-if="tryOver">
        <div class="layer">
          <div class="info">
            <p>试看结束，如需观看完整版请购买课程。</p>
            <p>
              <el-button type="text" @click="refreshVideo">
                <i class="el-icon-refresh-right"></i>重播
              </el-button>
            </p>
          </div>
        </div>
      </div>
      <div style="width: 100%; height: 600px;" v-else-if="watchModal.visible && watchModal.video">
        <VideoPlay :video_trial_time="video_trial_time" v-model:tryOver="tryOver" :canStudy="canStudy" :video="watchModal.video" :cur_uuid="curUuid" ref="video"></VideoPlay>
      </div>

    </el-dialog>

    <!-- 实验提示框 -->
    <el-dialog
        title="提示"
        v-model="exptModal.visible"
        width="500px" @close="exptModal.visible = false">
      <p class="text-center">
        <i class="el-icon-warning" :style="exptModal.iconStyle"></i>
        <span :style="exptModal.textStyle">您存在课程实验正在运行，确定结束原实验吗？</span>
      </p>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="continue_old_expt()">取消，继续原实验</el-button>
          <el-button @click="create_new_expt()" type="primary">确定，开始新实验</el-button>
        </span>
      </template>

    </el-dialog>
  </div>
</template>

<script>
  import VideoPlay from "components/course/VideoPlay"
  import { checkLogin, getCurRecord } from "api/course/course/study"
  import { startExpt } from "api/course/course/create"
  import { checkExpt, deleteExpt, createUserRecord,continueExptRecord } from "api/experiment/list"
  import { checkMessage} from 'api/promoter/selectPromo'
  export default {
    props: {
      chapter: Array,
      video_trial_time: Number,
      video_trial_num: Number,
      canStudy: Boolean,
      curUuid: String,
      curType: String
    },
    components: {
      VideoPlay
    },
    data() {
      return {
        chapter_data: this.chapter,
        is_hover: this.canStudy, //true: 已报名，有hover效果， false：未报名，无hover效果
        watchModal: {
          visible: false,
          // media_id: '',
          // video_id: '',
          video: {},
        },
        exptModal: {
          visible: false,
          iconStyle: 'font-size:32px;color:#E6A23C;vertical-align: middle;margin-right:10px;',
          textStyle: 'vertical-align: middle;'
        },
        tryOver: false,
        loginState: false,
        seen: false,
        del_data:{
          uuid:'',
          name:'',
          expt_url:'',
          course_uuid:'',
          old_cur_uuid:''
        },
        new_data_uuid:''
      }
    },
    mounted() {
      if(this.chapter.length > 0){
        this.initChapter(this.chapter)
      }
      checkLogin().then(res => {
        if (res.code == 1) {
          this.loginState = false
        }
        else {
          this.loginState = true
        }
      })
    },
    methods: {
      initChapter(chapter){ console.log(chapter);
        let video_index = 0
        let now = new Date()
        let all_chapters = new Array()
        let live_end = new Array()
        for(let i=0; i<chapter.length; i++){
          this.chapter[i].showIntro = true
          this.chapter[i].chapter_content.forEach(item => {
            all_chapters.push(item)
            if(item.face_uuid){
              item.start_at = dayjs(item.face_teaching.start_at).format('MM-DD HH:mm')
              item.end_at = dayjs(item.face_teaching.end_at).format('MM-DD HH:mm')
              let position = JSON.parse(item.face_teaching.position)
              if(position[0].label.indexOf('市') >= 0 || position.length == 3){
                item.city = position[0].label
              }
              else {
                item.city = position[1].label
              }
              if(now < new Date(item.face_teaching.start_at)){
                item.state = '未开始'
              }
              else if(now < new Date(item.face_teaching.end_at)){
                item.state = '进行中'
              }
              else {
                item.state = '已结束'
              }
            }
            else if(item.live_uuid){
              item.start_at = dayjs(item.live.start_at).format('MM-DD HH:mm')
              item.end_at = dayjs(item.live.end_at).format('MM-DD HH:mm')
              if(now < new Date(item.live.start_at)){
                item.state = '未开始'
              }
              else if(now < new Date(item.live.end_at)){
                item.state = '进行中'
              }
              else {
                item.state = '已结束'
                live_end.push(item)
              }
            }
            else if(item.video_uuid){
              item.video_index = video_index ++
            }
          })
        }
        if(live_end.length == all_chapters.length && live_end.length > 0){
          this.$emit('curTypeChange', '直播回放')
        }
        this.chapter_data = chapter
        this.getStudyState()
      },
      playVideo(video) {
        if (!this.loginState) {
          this.$emit('login')
        }
        else {
          checkMessage().then((res) => {
          if(res.data == 0) {

            this.watchModal.visible = true
            this.tryOver = false
            this.watchModal.video = video

          }})
        }
      },
      refreshVideo() {
        this.tryOver = false
        this.$refs.video.prepareData(this.$refs.video.video)
      },
      goStudyPage(item) {
        if(!this.loginState) {
          this.$emit('update:loginVisible', true)
          return
        }
        checkMessage().then((res) => {
          if(res.data == 0) {
            if (item.face_uuid) {
              this.goStudy(item.face_uuid, 'face_teaching');
            } else if (item.live_uuid) {
              this.goStudy(item.live_uuid, 'live')
            } else if (item.video_uuid) {
              this.goStudy(item.video_uuid, 'video')
            } else if (item.authentication_uuid) {
              this.goStudy(item.authentication_uuid, 'authentication')
            }
          }else {
              this.$emit('messageShow')
          }
        })
      },
      goStudy(uuid, type) {
        if(this.canStudy){
          if (!this.loginState) {
            this.loginState = false
            this.$emit('login')
          }
          else {
            var record_data = {'curr_uuid':this.curUuid,'type':type,'uuid':uuid}
            createUserRecord(record_data).then((res) => {
              this.$router.push(`/course/detail/${type}/${this.curUuid}/${uuid}`)
            })

          }
        }
      },
      getStudyState() {
        let data = {
          cur_uuid: this.curUuid
        }
        const that = this
        getCurRecord(data).then(res => {
          if(res.code === 0){
            let video_obj = {}
            let codes = []
            for(let i=0; i<res.video_records.length; i++){
              let record = res.video_records[i]
              if(record.is_finish || !(record.video_uuid in video_obj)){
                video_obj[record.video_uuid] = record.is_finish
              }
            }
            for(let i=0; i<res.codes.length; i++){
              codes.push(res.codes[i].authentication_uuid)
            }
            that.chapter_data.forEach(chapter => {
              chapter.chapter_content.forEach(item => {
                if(item.video_uuid){
                  if (item['video_uuid'] in video_obj){
                    item.state = video_obj[item['video_uuid']] == 1 ? '已学习' : '学习中'
                  }
                  else {
                    item.state = '未学习'
                  }
                }
                if(item.authentication_uuid){
                  if(codes.indexOf(item.authentication_uuid) >= 0){
                    item.state = '已获取'
                  }
                  else {
                    item.state = '发放中'
                  }
                }
                if(item.experiment_uuid){
                  if(item.state == 'running'){
                    item.state = '学习中'
                  }
                  else if (item.state == 'over'){
                    item.state = '已学习'
                  }
                  else if (item.state == ''){
                    item.state = '未学习'
                  }else if (item.state == 'null'){
                      item.state = '暂未发布'
                  }
              }
              })
            })
          }
        })
      },
      handleClose(){
        if(!this.tryOver){
          this.$refs.video.destoryVideoPlayer()
        }
        this.watchModal.video = null
        this.watchModal.visible = false
      },

      start_expt(course_uuid){
        checkMessage().then((res) => {
          if(res.data == 0) {
            if(this.canStudy){
              if (!this.loginState) {
                this.loginState = false
                this.$emit('login')
              }
              else {
                var check_data = {'course_uuid':course_uuid,'cur_uuid':this.curUuid}
                checkExpt(check_data).then((result) => {
                  if (result.data.code == 0 && result.data.old_expt == false) {
                    this.del_data.uuid = result.data.data['uuid']
                    this.del_data.name = result.data.data['name']
                    this.del_data.expt_url = result.data.data['expt_url']
                    this.del_data.course_uuid = result.data.data['course_uuid']
                    this.del_data.old_cur_uuid = result.data.old_cur_uuid
                    this.new_data_uuid = course_uuid

                    this.exptModal.visible = true
                  } else if(result.data.code == 0 && result.data.old_expt == true){
                      var record_data = {'curr_uuid':this.curUuid,'expt_uuid':course_uuid,'instance_uuid':result.data.data.uuid}
                        continueExptRecord(record_data).then(() => {
                          this.$router.push(`/expt/detail/${result.data.data.uuid}/?cur_uuid=${this.curUuid}&from=${window.location.pathname}`);
                        })
                  }else {
                    this.create_expt(course_uuid)
                  }
                }).catch(err => {
                  this.$alert(`${err}`, '提示', {
                    confirmButtonText: '确定'
                  });
                });

              }
            }
          }else {
              this.$emit('messageShow')
            }
        });
      },
      continue_old_expt(){
          var record_data = {'curr_uuid':this.del_data.old_cur_uuid,'expt_uuid':this.del_data.course_uuid,'instance_uuid':this.del_data.uuid}
          continueExptRecord(record_data).then(() => {
            this.$router.push(`/expt/detail/${this.del_data.uuid}/?cur_uuid=${this.del_data.old_cur_uuid}&from=${window.location.pathname}`);
          })
      },
      create_new_expt(){
          //  关闭旧实验开启新实验
          var del_data = {'uuid': this.del_data.uuid,'name':this.del_data.name}
          deleteExpt(del_data).then((result_data) => {
            if (result_data.data.code == 0) {
              this.create_expt(this.new_data_uuid)
            }
          })
      },

      create_expt(uuid){
          //  开始新实验
          var data = {'course_uuid':uuid,'curr_uuid':this.curUuid}
          startExpt(data).then((res) => {
            if (res.data.code == 0){
              this.$router.push(`/expt/detail/${res.data.expt_uuid}/?cur_uuid=${this.curUuid}&from=${window.location.pathname}`);
            }else{
              this.$alert(`${res.data.err_message}`, '提示', {
                confirmButtonText: '确定'
              });
            }
          }).catch(err=>{
          this.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });
      },

      continue_expt(uuid){
        checkMessage().then((res) => {
          if(res.data == 0) {
            if(this.canStudy){
              if (!this.loginState) {
                this.loginState = false
                this.$emit('login')
              }
              else {
                var check_data = {'course_uuid':uuid}
                checkExpt(check_data).then((res) => {
                  var record_data = {'curr_uuid':this.curUuid,'expt_uuid':uuid,'instance_uuid':res.data.data.uuid}
                  continueExptRecord(record_data).then(() => {
                    this.$router.push(`/expt/detail/${res.data.data.uuid}/?cur_uuid=${this.curUuid}&from=${window.location.pathname}`);
                  })
//                  alert('原实验的URL' + res.data.expt_url)
                })
              }
            }

          }else {
            this.$emit('messageShow')
          }
        });


      },
      preview_expt(item){
        checkMessage().then((res) => {
        if(res.data == 0) {
            if(this.canStudy){
              if (!this.loginState) {
                this.loginState = false
                this.$emit('login')
              }else{
                let domain = document.location.origin
                let url = domain + '/expt/view/'+ item.experiment.course_uuid
                window.open(url,'_blank')
              }}
        }else {
          this.$emit('messageShow')
        }
      });

      },

    },
    watch: {
      'chapter': function (val){
        this.initChapter(val)
      }
    }
  }
</script>

<style lang="scss">
  #detail_chapter {
    .chapter_list {
      > .intro {
        img {
          max-width: 100%;
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
.chapter_list {
  margin:0;
  > .intro {
    padding:10px;
    background:#fff;
    border:1px solid #f8f8f9;
    img {
      max-width:100%;
    }
  }
  dt {
    background:#f8f8f9;
    font-weight: bold;
    color:#333;
    font-size:16px;
    padding:20px 15px;
    display: flex;
    > span {
      flex:1;
    }
  }
  dd {
    margin:0;
    padding:15px;
    line-height:1.5;
    display:flex;
    .left {
      flex:1;
      color:#333;
      > i {
        color:#777;
        font-size:21px;
        margin-right:5px;
        position: relative;
        top: 2px;
      }
      > span {
        &.info {
          font-size:12px;
          color:#999;
          margin-left:20px;
        }
      }
    }
    .right {
      text-align:center;
      color:#999;
      span {
        display: block;
        padding-top: 6px;
      }
      .el-button {
        display:none;
        padding:9px 20px;
      }
      &.show_btns {
        .el-button {
          display:inline;
        }
      }
    }
    &:hover {
      background:#f8f8f9;
      .right {
        span {
          display:none;
          &.no_publish {
            display:block;
          }
        }
        .el-button {
          display: inline;
        }
      }
    }
  }
  &.is_hover {
    dd {
      -webkit-transition: all .2s ease;;
      transition: all .2s ease;
      cursor: pointer;
      &:hover {
        .title {
          color:#00adef;
        }
      }
    }
  }
}
  .try_watch_modal {
    .content {
      height:600px;
      background:#000;
      position:relative;
    }
    .layer {
      position:absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      background:rgba(0,0,0,0.5);
      .info {
        padding-top:20%;
        text-align:center;
        p {
          color:#fff;
          .el-button {
            color:#fff;
            i {
              margin-right:5px;
            }
            &:hover {
              color:rgba(255,255,255,0.9);
            }
          }
        }
      }

    }
  }
</style>
