import { fetch } from 'api/base/axios'

const URL = {
  getRelatedCurUrl: '/curriculum/cur/related_cur/',
  getCurOrderStateUrl: '/curriculum/cur/can_study/',
  getCurDetailUrl: '/curriculum/cur/user_cur_detail/',

}

export function getCurByCate(data) {
  return fetch(URL.getRelatedCurUrl, data)
}

export function getCurOrderState(uuid) {
  return fetch(URL.getCurOrderStateUrl + uuid + '/')
}

export function getCurDetail(uuid) {
  return fetch(URL.getCurDetailUrl + uuid + '/')
}
