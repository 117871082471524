import { fetch, post, del } from 'api/base/axios'

const URL = {
  exptListUrl: '/curriculum/experiment/get_expt_list/',
  deleteExptUrl: '/curriculum/experiment/delete_expt/',
  closeDiyExptUrl: '/curriculum/experiment/close_diy_expt/',
  checkExptUrl: '/curriculum/experiment/check_expt/',
  createUserRecordUrl: '/curriculum/experiment/create_user_record/',
  continueExptRecordUrl: '/curriculum/experiment/continue_expt_record/',
  checkZoneUserUrl: '/curriculum/experiment/check_zone_user/',
  getUserInfoUrl:'/user/manage/zone_join/'
}


export function getExptList (data) {
  return post(URL.exptListUrl, data)
}


export function deleteExpt (data) {
  return fetch(URL.deleteExptUrl, data)
}

export function closeDiyExpt (data) {
  return fetch(URL.closeDiyExptUrl, data)
}

export function checkExpt (data) {
  return post(URL.checkExptUrl, data)
}

export function checkZoneUser (data) {
  return post(URL.checkZoneUserUrl, data)
}

export function createUserRecord (data) {
  return post(URL.createUserRecordUrl, data)
}
export function continueExptRecord (data) {
  return post(URL.continueExptRecordUrl, data)
}

export function getUserInfo (data) {
  return post(URL.getUserInfoUrl, data)
}

