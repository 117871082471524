/**
 * 面授创建API
 */
import { fetch, post, del } from 'api/base/axios'

const URL = {
  filterUrl: '/curriculum/cate/list/',
  adminUrl: '/curriculum/cate/admin/list/',
  delUrl: '/curriculum/cate/delete/',
  saveUrl: '/curriculum/cate/save/',
  checkNameUrl: '/curriculum/cate/check_name_exist/',
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function listCate (data) {
  return fetch(URL.filterUrl, data)
}

export function listAdminCate (data) {
  return fetch(URL.adminUrl, data)
}

export function delCate (params) {
  return post(URL.delUrl, params)
}

export function saveCate(params) {
  return post(URL.saveUrl, params)
}

export function checkNameExist (id, value, parent_id) {
  return fetch(URL.checkNameUrl, {'id': id, 'name': value, 'parent_id': parent_id})
}
