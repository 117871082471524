<template>
  <div>
    <cur-list v-if="type == 1 || type == 2" :category_lv1_id="category_lv1_id" :category_lv2_ids="category_lv2_ids"
      :initCate="initCate" :initAuth="initAuth" :curFilter="curFilter"></cur-list>
    <cur-detail v-else-if="type == 3" :cur_uuid="cur_uuid"></cur-detail>
    <div class="custom_page_wrap" v-else>
      <iframe v-if="type == 4" :src="pageUrl" width="100%" height="100%" frameborder="0"></iframe>
      <iframe v-else-if="type == 5" :src="linkUrl" width="100%" height="100%" frameborder="0"></iframe>
    </div>
  </div>
</template>

<script>
import { listNav } from "api/nav/list"
import CurList from "components/course/CurList"
import CurDetail from "components/course/CurDetail"
import { pageList } from "api/page/index"
export default {
  name: "CustomPage",
  components: {
    CurList,
    CurDetail
  },
  data () {
    return {
      nav_name: '',
      nav_url: '',
      type: '',
      category_lv1_id: '',
      category_lv2_id: '',
      curFilter: {},
      cur_uuid: '',
      initCate: false,
      initAuth: true,
      pageUrl: '',
      linkUrl: '',
    }
  },
  mounted() {
    this.nav_url = this.$route.params.nav_url.join('/');
    this.getNav();
  },
  methods: {
    getNav() {
      const that = this
      let filters = {
        nav_url: this.nav_url,
        // state: 1,
        need_count: false
      }
      listNav(filters).then(res => {
        if(res.data.length > 0){
          let data = res.data[0]
          this.nav_name = data.name;
          that.type = data.type
          if (data.type == 1) {
            if(data.category_lv2_ids){
              that.category_lv2_ids = data.category_lv2_ids
              if(data.category_lv2_ids.length > 1){
                that.initCate = true
              }
            }
            else if(data.category_lv1_id){
              that.initCate = true
              that.category_lv1_id = data.category_lv1_id
            }
            else {
              that.initCate = true
            }
          }
          else if(data.type == 2) {
            that.initCate = true
            that.curFilter = JSON.parse(data.cur_filter)
          }
          else if(data.type == 3){
            that.cur_uuid = data.cur_uuid
          }
          else if(data.type == 4) {
            that.showPageDetail(data.page_id)
          }
          else if(data.type == 5){
            that.linkUrl = data.link_url
          }
          //标签页title
          document.title = `${ this.nav_name }-未来网络学堂`;

          //reload 7moor js
          const s = document.createElement('script');
          s.type = 'text/JavaScript';
          s.src = 'https://webchat.7moor.com/javascripts/7moorInit.js?accessId=a4c3d330-5dcc-11ea-b7ff-07dd5ece4b95&autoShow=true&language=ZHCN';
          document.body.appendChild(s);

        } else {
          that.$alert('您要访问的页面不存在', '提示', {
            confirmButtonText: '确定',
          });
        }
      })
    },
    showPageDetail(id) {
      let filters = {'page_id': id}
      pageList(filters).then((res => {
        this.pageUrl = res.data[0].page_file_dir
        setTimeout(function (){
          var b_width = Math.max(document.body.scrollWidth,document.body.clientWidth);
          var b_height = Math.max(document.body.scrollHeight,document.body.clientHeight);
          var c_iframe = document.getElementById("test");
          c_iframe.src = c_iframe.src + "#" + b_width + "|" + b_height;
        }, 1000)
      }))
    },
    goPath(path) {
      this.$router.push(path)
    },
  },
  watch: {
    $route(to,from){
      if(to.name == 'CustomPage'){
        this.name = to.params.nav_name
        this.nav_url = this.$route.params.nav_url.join('/');
        this.type = ''
        this.category_lv1_id = ''
        this.category_lv2_ids = ''
        this.curFilter = {}
        this.getNav()
      }
    }
  }
}
</script>

<style lang="scss">
.container .el-main {
  min-height: auto !important;
}
.custom_page_wrap {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
</style>
